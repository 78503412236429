import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Menu as MenuIcon } from 'react-feather';
import { THEMES, includesAny } from 'src/constants';
import LogoThree from 'src/components/LogoThree';
import screenRoutes from 'src/config/screen.routes';
import { useSelector } from 'react-redux';
import Account from './Account';
// import Search from './Search';
import Settings from './Settings';
import Notifications from './Notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.name === THEMES.ACELERA ? {
          backgroundColor: theme.palette.background.default,
        } : {},
    ...theme.name === THEMES.ONE_DARK ? {
          backgroundColor: theme.palette.secondary.main,
        } : {},
  },
  toolbar: {
    minHeight: 64,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  title: {
    color: '#7a7b74',
  },
  logo: {
    width: 232,
    marginLeft: 39,
  },
  button: {
    textTransform: 'none',
  },
  buttonLeaf: {
    color: '#FFF',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    color: '#FFF',
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    color: '#FFF',
  },
}));

const navTopOptions = [
  {
    title: 'dashboard',
    roleAccess: ['SuperUser',
'Admin',
'AccountingDept'],
    href: `${screenRoutes.accounting.dashboard}`,
  },
  {
    title: 'Loans',
    roleAccess: [
      'SuperUser',
      'Admin',
      'LoanServicer',
      'CustomerService',
      'AccountingDept',
    ],
    href: `${screenRoutes.loans.list}`,
  },
  {
    title: 'reports',
    roleAccess: [
      'SuperUser',
      'Admin',
      'LoanServicer',
      'CustomerService',
      'AccountingDept',
    ],
    href: `${screenRoutes.reports.list}`,
    items: [
      {
        title: 'accountsReceivableAging',
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        href: `${screenRoutes.reports.loansAccountReceivableAgingReport}`,
      },
      {
        title: 'delinquency',
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        href: `${screenRoutes.reports.delinquency}`,
      },
      {
        title: 'loansReceivable',
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        href: `${screenRoutes.reports.loansReceivableReport}`,
      },
      {
        title: 'portfolioBalance',
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        href: `${screenRoutes.reports.portfolioBalance}`,
      },
      {
        title: 'insuranceReport',
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        href: `${screenRoutes.reports.insuranceReport}`,
      },
      {
        title: 'collateralReport',
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        href: `${screenRoutes.reports.collateralReport}`,
      },
      {
        title: 'loanClosureReport',
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        href: `${screenRoutes.reports.loanClosureReport}`,
      },
      {
        title: 'eQLoanListReport',
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        href: `${screenRoutes.reports.eQLoanListReport}`,
      },
    ],
  },
];

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const { user } = useSelector((state) => state.account);
  const { t } = useTranslation();
  const roles = user?.roles ?? [];
  const classes = useStyles();
  const [openMenu, setOpenMenu] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setOpenMenu(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        className={clsx(classes.root, className)}
        component="nav"
        {...rest}
      >
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logo} display="flex">
            <RouterLink to="/app/management/loans">
              <LogoThree />
            </RouterLink>
          </Box>
          <IconButton
            aria-label="open drawer"
            color="inherit"
            color="#7a7b74"
            edge="start"
            onClick={onMobileNavOpen}
            sx={{ ml: 1, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'none', md: 'flex' },
            }}
          >
            {navTopOptions.map((item) => {
              const hasAccess = item.roleAccess ? includesAny(item.roleAccess, roles) : false;

              if (!hasAccess) return <></>;

              if (item?.items) {
                return (
                  <>
                    <Button
                      key={`nav-top-option-${item.title}`}
                      className={classes.button}
                      onClick={handleOpenUserMenu}
                      sx={{ color: 'grey' }}
                    >
                      <span className={classes.title}>{t(item.title)}</span>
                      {openMenu ? (
                        <ExpandLessIcon color="inherit" size="small" />
                      ) : (
                        <ExpandMoreIcon color="inherit" size="small" />
                      )}
                    </Button>
                    {item.items.map((subItem) => (
                      <Menu
                        anchorEl={openMenu}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        id="menu-appbar"
                        keepMounted
                        onClose={handleCloseUserMenu}
                        open={openMenu}
                        sx={{ mt: '45px' }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        {item?.items
                          && item.items.map((subItem) => (
                            <MenuItem
                              key={`nav-top-sub-option-${subItem.title}`}
                              component={RouterLink}
                              onClick={handleCloseUserMenu}
                              to={subItem.href}
                            >
                              <span className={classes.title}>
                                {t(subItem.title)}
                              </span>
                            </MenuItem>
                          ))}
                      </Menu>
                    ))}
                  </>
                );
              }

              return (
                <Button
                  key={`nav-top-option-${item.title}`}
                  className={classes.button}
                  component={RouterLink}
                  to={item.href}
                >
                  <span className={classes.title}>{t(item.title)}</span>
                </Button>
              );
            })}
          </Box>
          <Box flexGrow={1} ml={2} />
          {/* Hidding search bar */}
          {/* <Search /> */}
          <Notifications />
          <Settings />
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
